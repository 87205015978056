import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-wasm';
import * as tflite from '@tensorflow/tfjs-tflite';
import * as tfd from '@tensorflow/tfjs-data';
import Compressor from 'compressorjs';
import * as cv from './js/opencv.js'; 
// import { arrShadesData } from './js/shades';

let webcam;
let segmentationModel;
let model;
let width, height;
let img_height = 256;
let img_width = 256;
let name = 'tfjs_v6_100_256';
let model_path;
let SCALE_MULTIPLIER = 0.8;
let SCALE_ZOOM = 1;
let MAX_SCALE_ZOOM = 2;
let MIN_SCALE_ZOOM = 1;
var DEFAULT_SCALE_ZOOM = 1;
var DEFAULT_SCALE_MULTIPLIER = 0.8;
let LiveTryOn = false;
model_path = 'tfjs_v6_100_256/hair_segmentation_rework_tf_mobilenetv3_large_minimal_100_imagenet_v3_focal_cont_gtmatt_3losses_index_epoch_59_float16.tflite';
// Try-on-Variables
let FPS_Section = document.getElementById('fps-section');
let Controls_Section = document.getElementById('controlsSection');
let Try_On_Area = document.getElementById('try-on-area');
let Scroll_Down_Target =  document.getElementById("scrollto");
let Output_Canvas = document.getElementById('outputCanvas');
let Back_Button = document.getElementById('backButton');
let Input_Canvas = document.getElementById('tryon-live-canvas-0');
let Input_Image = document.getElementById('img2');
let Input_Video = document.getElementById('webcam');
let Output_Video = document.getElementById('mask');
let Try_On_Controls_Section = document.getElementById('try-on-section');
let tabsContainer = document.getElementById('tabsContainer');
let colorPaletteContainer = document.getElementById('colorPaletteContainer');
let Flipper_Icon = document.getElementById('flipper'); 
let TopContentSection = document.getElementById('margin-class');
let currentCamera = 'user';
let continuePredicting = true;
var CanvasesContainer = document.getElementById('canvasesContainer');
var selectedShadeNameDiv = document.getElementById('selectedShadeName');
let Tryon_Media_Container = document.getElementById('container-tryon-media');
let Tryon_Container = document.querySelector('.container-tryon-media'); 
// console.log(model_path,img_size)

// Loads mobilenet and returns a model that returns the internal activation
// we'll use as input to our classifier model.
// async function loadTruncatedMobileNet() {
//   var segmentationModel = await tf.loadGraphModel(model_path);
//   return segmentationModel;
// }

async function loadModel() {
  await tflite.setWasmPath(
    'https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-tflite@0.0.1-alpha.8/dist/'
  );
  // console.log(model_path);
  var model = await tflite.loadTFLiteModel(model_path);
  return model;
}

let hairColor = [null,null,null];

let adjustWithLuminance = .25;
/////////////////////////////////////////////////////////////////////////*****************************************88



/////////////////////////////////////////////////////////////////////////////////////////////////////////**************************************
function averageLuminanceFiltered(imageData, maskData) {
  
  var luminanceSum = 0;
  var pixelCount = 0;

  for (var i = 0; i < imageData.length; i += 3) {
      // Apply mask: Only consider pixels where mask value is 1
      if (maskData[i / 3] > 0.5) {
          // Get grayscale value from RGB (assuming image is in RGBA format)
          var grayValue = 0.2126 * imageData[i] + 0.7152 * imageData[i + 1] + 0.0722 * imageData[i + 2];
          luminanceSum += grayValue;
          pixelCount++;
      }
  }

  return luminanceSum / pixelCount;
}
function mapValue(value, fromLow, fromHigh, toLow, toHigh) {
  // Map value from the input range to the output range
  return toLow + (toHigh - toLow) * ((value - fromLow) / (fromHigh - fromLow));
}
// Map luminance based on average intensity and range
function mapLuminance(averageIntensity, lowerLuminance, upperLuminance) {
  var upperIntensity = 1;
  var lowerIntensity = 0;

  // Calculate the total intensity range
  var intensityRange = upperIntensity - lowerIntensity;

  // Calculate the luminance range
  var luminanceRange = upperLuminance - lowerLuminance;

  // Calculate the scaled intensity within the range [0, 1]
  var scaledIntensity = (averageIntensity - lowerIntensity) / intensityRange;

  // Map the scaled intensity to the luminance range
  var mappedLuminance = lowerLuminance + (scaledIntensity * luminanceRange);

  return mappedLuminance;
}
/////////////////////////////****************************


var sobelX = tf.tensor2d([
  [-1, 0, 1],
  [-2, 0, 2],
  [-1, 0, 1]
], [3, 3]);

var sobelY = tf.tensor2d([
  [-1, -2, -1],
  [0, 0, 0],
  [1, 2, 1]
], [3, 3]);

// Function to apply Sobel filters
// Function to apply Sobel filters to a single-channel image
function applySobelFilters(grayscaleImage) {
  // Expand dimensions to create a 4D tensor [1, height, width, 1]
  var expandedImage = grayscaleImage.expandDims(0).expandDims(-1);

  // Create 4D Sobel filters [3, 3, 1, 1]
  var sobelXFilter = sobelX.expandDims(2).expandDims(3);
  var sobelYFilter = sobelY.expandDims(2).expandDims(3);

  var sobelXImage = tf.conv2d(expandedImage, sobelXFilter, [1, 1], 'same');
  var sobelYImage = tf.conv2d(expandedImage, sobelYFilter, [1, 1], 'same');

  var gradientMagnitude = tf.sqrt(sobelXImage.square().add(sobelYImage.square()));

  // Remove the added dimensions to return to shape [height, width, 1]
  return gradientMagnitude.squeeze();
}
////////////////////********************************
function calculatePercentile(array, percentile) {
    var sortedArray = array.slice().sort((a, b) => a - b);
    var index = Math.ceil((percentile / 100) * sortedArray.length);
    return sortedArray[index - 1];
}
function blendMediapipe(image, weight,adjustWithLuminance,additionLuminance,avg) {
//console.log("Blending code1")
  let mean = tf.tensor1d([0.299, 0.587, 0.114]);
  let inputStrength;

  if (window.location.pathname.includes('/builder.html')) { 
  inputStrength = parseFloat(document.getElementById("strength").value);
  adjustWithLuminance = (1 - inputStrength / 100.0);
  }
  else{
  adjustWithLuminance = (1 - 0 / 100.0);
  }

 let luminance = tf.tidy(() => image.mul(mean).sum(2).mul(adjustWithLuminance).add(additionLuminance).sub(adjustWithLuminance));
 
 //let luminance = tf.tidy(() => image.mul(mean).sum(2));


  // weight = tf.where(mask2, weight.add(0.4), weight);
   //weight = tf.where(mask3, weight.add(0.6), weight);
  //console.log("Blending code2")
    
   
  
  var color = tf.tensor1d(hairColor);
  //color=color.sub(10.9)
  var mixValue = tf.tidy(() => weight.mul(luminance).expandDims(2));
  var ones = tf.onesLike(image);
  
  ///////////////////////////*****************************************


// Find edges using a Canny edge detection algorithm
// Define Sobel filters for edge detection
// Define Sobel filters for edge detection




//console.log("Blending code3")

var blended = tf.tidy(() => {

    var blendedWithWhiteReflections = image.mul(ones.sub(mixValue)).add(mixValue.mul(color));

    // Combine the blended areas with the original image
    //if (hairColor[0] <0 && hairColor[1] <0)
    //{blendedWithWhiteReflections = blendedWithWhiteReflections.clipByValue(0, 1).add(resultTensor.expandDims(2));}

    return blendedWithWhiteReflections.clipByValue(0, 1);
});
//console.log("Blending code4")
////////////////////////////////////***********************************

//var blended = tf.tidy(() => image.mul(ones.sub(mixValue)).add(mixValue.mul(color)).clipByValue(0, 1));
  color.dispose();
  luminance.dispose();
  mixValue.dispose();
  ones.dispose();
  //weight.dispose();
  image.dispose();
  mean.dispose();
  return blended;
}


////////////////////////////////////////////////////////////////////////////New New New New New////////////////////////////////////////////////////////////////

function convert32FC3To8UC3(mat32f) {
    var mat8u = new cv.Mat(mat32f.rows, mat32f.cols, cv.CV_8UC3);

            // Copy data from 32FC3 to 8UC3 directly
            for (let row = 0; row < mat32f.rows; row++) {
                for (let col = 0; col < mat32f.cols; col++) {
                    var index = (row * mat32f.cols + col) * 3;
                    mat8u.data[index] = Math.min(Math.max(mat32f.data32F[index], 0), 255);     // B
                    mat8u.data[index + 1] = Math.min(Math.max(mat32f.data32F[index + 1], 0), 255); // G
                    mat8u.data[index + 2] = Math.min(Math.max(mat32f.data32F[index + 2], 0), 255); // R
                }
            }

            return mat8u;
}

function changeV(vChannel, mask, targetV,summask) {
	// Calculate the mean of v * mask
	var vMasked = new cv.Mat();
	cv.multiply(vChannel, mask, vMasked);

	var vMean = tf.tensor(vMasked.data32F).sum().dataSync()[0]/ summask;
	
	//Till this point corrected and validated 

	// Compute alpha
	var alphaValue = targetV / vMean;
	

	// Apply the non-linear transformation
	var x = new cv.Mat();
	cv.divide(vChannel, new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(255)), x);
	var oneMat = new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(1));
	cv.subtract(oneMat, x, x);
	cv.pow(x, alphaValue, x);
	cv.subtract(oneMat, x, x);
	cv.multiply(x, new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(255)), x);

	// Update the V channel
	x.copyTo(vChannel);

	// Clean up resources
	vMasked.delete();
	x.delete();
	oneMat.delete();
}

function changeS(vChannel, mask, targetV,summask) {
	// Calculate the mean of v * mask
	var vMasked = new cv.Mat();
	cv.multiply(vChannel, mask, vMasked);

	var vMean = tf.tensor(vMasked.data32F).sum().dataSync()[0]/ summask;
	
	//Till this point corrected and validated 
	
	// console.log("target");
	// console.log(targetV);
	
	// Compute alpha
	var alphaValue = targetV / vMean;
	

	// Apply the non-linear transformation
	var x = new cv.Mat();
	cv.divide(vChannel, new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(255)), x);
	var oneMat = new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(1));
	cv.subtract(oneMat, x, x);
	cv.pow(x, alphaValue, x);
	cv.subtract(oneMat, x, x);
	cv.multiply(x, new cv.Mat(vChannel.rows, vChannel.cols, vChannel.type(), new cv.Scalar(255)), x);

	// Update the V channel
	x.copyTo(vChannel);

	// Clean up resources
	vMasked.delete();
	x.delete();
	oneMat.delete();
}

function Algofacerecolor(img, oriImage, maskTensor) {





    // Step 4: Create an OpenCV.js Mat with float type
    var mask = new cv.Mat(maskTensor.shape[0], maskTensor.shape[1], cv.CV_32FC1);

    // Step 5: Set the data to the OpenCV.js Mat
    mask.data32F.set(maskTensor.dataSync());
    
    
    
    



    console.log(`Mask - Rows: ${mask.rows}, Cols: ${mask.cols}, Channels: ${mask.channels()}`);

    // Create a 3-channel mask by repeating the single channel across all channels
    var mask3Channel = new cv.Mat();
    cv.cvtColor(mask, mask3Channel, cv.COLOR_GRAY2BGR);
    
    
    

        let color = hairColor.map(value => Math.floor(value * 255));
	// Convert the color to a Mat
	var colorMat = new cv.Mat(1, 1, cv.CV_32FC3, new cv.Scalar(color[2], color[1], color[0]));
	var colorHSV = new cv.Mat();
	cv.cvtColor(colorMat, colorHSV, cv.COLOR_BGR2HSV);
	
	
	console.log("Checkpoint 1");
	
	

	// Convert img to HSV
	var imgHSV = new cv.Mat();
	cv.cvtColor(img, imgHSV, cv.COLOR_BGR2HSV);
	console.log("Checkpoint 2");
	// Calculate the mean value of the masked area
	var maskedHSV = new cv.Mat();
	
	
	
	cv.multiply(imgHSV, mask3Channel, maskedHSV);
	
	
	
	console.log("Checkpoint 3");
	
	
	
	let sumval=tf.tensor(maskedHSV.data32F).sum().dataSync()[0]
	let summask=tf.tensor(mask.data32F).sum().dataSync()[0]
	
	

	var vMean1 = sumval / summask;
	
	console.log(vMean1);


	
	
	
     // if (vMean1 < 260 || vMean1 > 330) {
      var overlay = new cv.Mat(img.rows, img.cols, img.type(), new cv.Scalar(0, 255, 255));
	    cv.addWeighted(overlay, 0.2, img, 0.8, 0, img);
	    // console.log("sat changed");
	    cv.cvtColor(img, imgHSV, cv.COLOR_BGR2HSV);
	//}
	
	
	//Will use vMean1 for handling black input
	
	var channels = new cv.MatVector();
	cv.split(imgHSV, channels);
	
	
        var hChannel = channels.get(0);
        
        
	var targetH = new cv.Mat(hChannel.rows, hChannel.cols, hChannel.type(), new cv.Scalar(colorHSV.data32F[0]));
	targetH.copyTo(hChannel);
	
	
	
	console.log("Checkpoint 4");
	// Apply change_v to the V channel
	var vChannel = channels.get(2);
	


	changeV(vChannel, mask, colorHSV.data32F[2],summask);
	



	
	console.log("Checkpoint 5");
	// Apply change_v to the S channel
	
	var sChannel = channels.get(1);
	changeS(sChannel, mask, colorHSV.data32F[1],summask);
	
	cv.merge(channels, imgHSV);
	
	console.log("Checkpoint 6");
	
	var hair = new cv.Mat();
	cv.cvtColor(imgHSV, hair, cv.COLOR_HSV2RGB);
	cv.multiply(hair, mask3Channel, hair);
	
	console.log("Checkpoint 7");

	
	
	var invMask = new cv.Mat();
	
	var oneMat = new cv.Mat(mask3Channel.rows, mask3Channel.cols, mask3Channel.type(), new cv.Scalar(1,1,1));
	
	
	cv.subtract(oneMat, mask3Channel, invMask);
	
	

	var origin = new cv.Mat();
	cv.multiply(oriImage, invMask, origin);
	


	// Combine images
	var imgOutput = new cv.Mat();
	cv.add(origin, hair, imgOutput);
	console.log("Checkpoint 8");
	
				// Show the result
				
	
	


	
	
	
	// Cleanup
	img.delete();
	oriImage.delete();
	imgHSV.delete();
	colorMat.delete();
	colorHSV.delete();
	maskedHSV.delete();
	//overlay.delete();
	channels.delete();
	hChannel.delete();
	targetH.delete();
	vChannel.delete();
	//targetV.delete();
	sChannel.delete();
	
	hair.delete();
	invMask.delete();
	origin.delete();
	//imgOutput.delete();
	return imgOutput;
	

	
	
}

//////////////////////////////////////////////////////////////////////////////////////New End New End/////////////////////////////////////////////////////////


let outputWidth;
let outputHeight;

if (window.location.pathname.includes('/builder.html')) { 
  document.addEventListener("DOMContentLoaded", () => {
    Coloris({
      alpha: false,
      format: 'rgb',
      theme: 'polaroid'
    });
  });

  }
// document.addEventListener('coloris:pick', event => {

//   var rgb = event.detail.color;
//   rgb = rgb.substring(4, rgb.length-1).replace(/ /g, '').split(',');

//   // console.log('New color', event.detail.color);
//   hairColor = [parseFloat(rgb[0]/255.0), parseFloat(rgb[1]/255.0), parseFloat(rgb[2]/255.0)]

// });
function handleColorSelection(rgbValues) {
  var normalizedRGB = rgbValues.map(value => value / 255.0);
  hairColor = normalizedRGB;
}
document.addEventListener('coloris:pick', event => {
  var rgb = event.detail.color
    .substring(4, event.detail.color.length - 1)
    .replace(/ /g, '')
    .split(',')
    .map(Number);

  // Handle color selection
  handleColorSelection(rgb);
  if(!LiveTryOn){
  updateImageColor();
  }
});
// document.getElementById('colorShadesList').addEventListener('click', function (event) {
//   if (event.target.classList.contains('color-shade')) {
//     var rgbValues = event.target.getAttribute('data-rgb').split(',').map(Number);
//     handleColorSelection(rgbValues);
//   }
// });


async function check_select() {
  let model_name = document.getElementById("select_model").value;

  if (model_name != name) {
    model_path = model_name + "/model.json"
    segmentationModel.dispose();
    segmentationModel = await loadModel();
    name = model_name;
    if (name == "tfjs_v6_100_256") {
      img_height = 256;
      img_width = 256;
    }
    else if (name == "tfjs_v6_100_384") {
      img_height = 384;
      img_width = 384;
    }
    else if (name == "tfjs_v6_100_512" || name == "tfjs_v7_100_512") {
      img_height = 512;
      img_width = 512;
    }
    else if (name == "tfjs_v6_100_256_512") {
      img_height = 512;
      img_width = 256;
    } else {
      img_height = 256;
      img_width = 256;
    }
  }

}

async function predict() {
  var i = 0;
  let outputBlended;
  let fps;
  let fpsTotal=0;
  while (continuePredicting) {
    
    if (i % 1 == 0 && LiveTryOn) {
      
      await check_select();

      var images = await getImage();
      var img = images[1];
      var img_show = images[0];

      var start1 = window.performance.now();

      if (i % 100) {
        var aspect_ratio = outputHeight/outputWidth;
        document.getElementById("mask").style.width = 0.5*window.innerHeight/aspect_ratio;
        document.getElementById("mask").style.height = 0.5*window.innerHeight;
        document.getElementById("tryon-live-canvas-0").style.width = 0.5 * window.innerHeight / aspect_ratio;
        document.getElementById("tryon-live-canvas-0").style.height = 0.5 * window.innerHeight;
        // console.log(outputHeight);
        // console.log(outputWidth);

      }

      
      var startTime = window.performance.now();

      // var img_preprocessed = tf.image.cropAndResize(img,[[0, 0.2, 1, 0.8]], [0], [img_size,img_size]) //.toFloat().div(255), [[0, 0.25, 1, 0.75]], [0], img_size).clipByValue(0,1) //.resizeBilinear([img_size,img_size]);

      
      
      
        var maskCanvas = Output_Video;
      var originalCanvas = Input_Canvas;
      if (i%2==0)
      {

     var start_model = window.performance.now();
      var output = segmentationModel.predict(img);
      
      




      // console.log('model')
      // console.log(end_model-start_model)

      // var endTime = window.performance.now()
      // console.log(`Model took  ${endTime - startTime}`)
      // var startTime = window.performance.now()
      
      
       tf.engine().startScope()


      

      var start_resize = window.performance.now();
      
      var outputMatt =  tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height,img_width]).clipByValue(0,1).reshape([img_height,img_width,1]).resizeBilinear([outputHeight,outputWidth]).squeeze());
      // var outputMatt = tf.tidy(() => outputMatt2.reshape([img_height,img_width,1]).resizeBilinear([outputHeight,outputWidth]).squeeze());

      Binarymask=outputMatt.mul(255)
      var end_model = window.performance.now();


      fps = (1000/(end_model-start_model)).toFixed(2) ;

      document.getElementById("model_fps").innerHTML = fps;

      
      var end_resize = window.performance.now();
      
     // if (i>0){
     	//outputBlended.dispose();
     	//let outputBlended;
     	//}
     	
     	if (i<2){
     	
	   avg=averageLuminanceFiltered(img_show.squeeze().dataSync(),outputMatt.dataSync())
	  
	  
	  //60 to 100 with 60 to dark and 100 to bright

	  //adjustWithLuminance = parseFloat(document.getElementById("strength").value)/100.0;
	  //additionLuminance=1
	  
	  adjustWithLuminance=mapLuminance(avg, 0.9, 1);
	  
	  additionLuminance= mapValue(adjustWithLuminance, 0.9, 1, 1, 0.8)
  }

	////////////////***************** Intensity Control Code//////////////////////
  
 
  if (avg<0.2 && hairColor[0] >0.5 && hairColor[1] >0.5)
  {
  var mask = tf.greater(outputMatt, 0.8);
  blendingIntensity=1.2
  outputMatt = tf.where(mask, outputMatt.add(blendingIntensity), outputMatt);
  }
 
  
  ////////////////////************Intensity Control Code Ends/////////////////////////
  
  
      outputBlended= blendMediapipe(img_show.squeeze(),  outputMatt,adjustWithLuminance,additionLuminance); //.resizeBilinear([256,256])
 
      outputMatt.dispose();
      // outputMatt2.dispose();
      output.dispose();
      // output.dispose();
      img.dispose();
      
      }
      
      var startTimeR = window.performance.now()
      var tensorToUse = hairColor[0] == null ? img_show.squeeze() : outputBlended;
      await tf.browser.toPixels(tensorToUse, maskCanvas);
      await tf.browser.toPixels(img_show.squeeze(), originalCanvas);  
      // await tf.browser.toPixels(outputBlended, maskCanvas);
      img_show.dispose();
      
      if (i>0 && (i+1)%2==0){
      
      tf.engine().endScope()
      }
      
       
      
      var endTimeR = window.performance.now()
      fps = (1000/(endTimeR-startTimeR)).toFixed(2);



    
      document.getElementById("render_fps").innerHTML = fps;

   


      
 

      var end1 = window.performance.now();

      fpsTotal = ((1000/(end1-start1))+fpsTotal)/2;

      document.getElementById("fps").innerHTML = fpsTotal.toFixed(2);

// do your thing
       
       
       
      
      let count3 = tf.memory().numTensors;
      console.log( count3);
      
      
    //   console.log(
    //     "Make sure we cleaned up",
    //     tf.memory().numTensors,
    //     tf.memory().numBytesInGPU,
    //   );
      
      // var endTime = window.performance.now()
      // console.log(`Total took ${endTime - startTime}`)

    }
    let count3 = tf.memory().numTensors;
    console.log( count3);
    i++;
    await tf.nextFrame();
  }
}

async function predictold() {
  var i = 0;
  while (continuePredicting) {
    i++;
    if (i % 1 == 0 && LiveTryOn) {
      await check_select();

      var images = await getImage();
      var img = images[1];
      var img_show = images[0];

      var start1 = window.performance.now();

      if (i % 100) {
        var aspect_ratio = outputHeight / outputWidth;
        document.getElementById("mask").style.width = 0.5 * window.innerHeight / aspect_ratio;
        document.getElementById("mask").style.height = 0.5 * window.innerHeight;
        document.getElementById("tryon-live-canvas-0").style.width = 0.5 * window.innerHeight / aspect_ratio;
        document.getElementById("tryon-live-canvas-0").style.height = 0.5 * window.innerHeight;
        // console.log(outputHeight);
        // console.log(outputWidth);

      }

      let fps;
      var startTime = window.performance.now();

      // var img_preprocessed = tf.image.cropAndResize(img,[[0, 0.2, 1, 0.8]], [0], [img_size,img_size]) //.toFloat().div(255), [[0, 0.25, 1, 0.75]], [0], img_size).clipByValue(0,1) //.resizeBilinear([img_size,img_size]);

      var start_model = window.performance.now();

      var output = segmentationModel.predict(img);




      // console.log('model')
      // console.log(end_model-start_model)

      // var endTime = window.performance.now()
      // console.log(`Model took  ${endTime - startTime}`)
      // var startTime = window.performance.now()


      var maskCanvas = Output_Video;
      var originalCanvas = Input_Canvas;

      var start_resize = window.performance.now();

      var outputMatt = tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height, img_width]).clipByValue(0, 1).reshape([img_height, img_width, 1]).resizeBilinear([outputHeight, outputWidth]).squeeze());
      // var outputMatt = tf.tidy(() => outputMatt2.reshape([img_height,img_width,1]).resizeBilinear([outputHeight,outputWidth]).squeeze());

      var end_model = window.performance.now();


      fps = (1000 / (end_model - start_model)).toFixed(2);

      document.getElementById("model_fps").innerHTML = fps;


      var end_resize = window.performance.now();

      var startTime = window.performance.now()

      var outputBlended = blendMediapipe(img_show.squeeze(), outputMatt); //.resizeBilinear([256,256])

      var tensorToUse = hairColor[0] == null ? img_show.squeeze() : outputBlended;
      await tf.browser.toPixels(tensorToUse, maskCanvas);
      await tf.browser.toPixels(img_show.squeeze(), originalCanvas);

      var endTime = window.performance.now()
      fps = (1000 / (endTime - startTime)).toFixed(2);
      document.getElementById("render_fps").innerHTML = fps;

      var startTime = window.performance.now()



      outputBlended.dispose();
      outputMatt.dispose();
      // outputMatt2.dispose();
      output.dispose();
      // output.dispose();
      img.dispose();
      img_show.dispose();

      var end1 = window.performance.now();

      fps = (1000 / (end1 - start1)).toFixed(2);

      document.getElementById("fps").innerHTML = fps;

      //   console.log(
      //     "Make sure we cleaned up",
      //     tf.memory().numTensors,
      //     tf.memory().numBytesInGPU,
      //   );

      // var endTime = window.performance.now()
      // console.log(`Total took ${endTime - startTime}`)

    }
    else{
      return
    }
    let count3 = tf.memory().numTensors;
    console.log(count3);
    await tf.nextFrame();
  }
}



/**
 * Captures a frame from the webcam and normalizes it between -1 and 1.
 * Returns a batched image (1-element batch) of shape [1, w, h, c].
 */
async function getImage() {
  var img = await webcam.capture();
  let checkMobile = await isMobileBrowser();
  if (checkMobile) {  
    // Get the video's natural aspect ratio   
    let aspectRatio = Input_Video.videoWidth / Input_Video.videoHeight;   
    // Calculate the optimal output dimensions  
    outputWidth = Input_Video.width;  
    outputHeight = Input_Video.width / aspectRatio;  
    if (outputHeight > Input_Video.height) {  
      outputHeight =Input_Video.height;  
      outputWidth = Input_Video.height * aspectRatio;  
    }  
    // **CORRECTED:**  Set the video element's size  
    Input_Video.width = Math.floor(outputWidth);  
    Input_Video.height = Math.floor(outputHeight);  
    // Optional: Set the container's dimensions for more precise fitting  
    // (adjust container element selector)  
    // document.querySelector("#mask").style.width = `${outputWidth}px`;  
    // document.querySelector("#mask").style.height = `${outputHeight}px`;   
  }

  else{ 
    outputWidth = Input_Video.width;
    outputHeight = Input_Video.height;
  let aspectRatio = Input_Video.width / Input_Video.height;
  if (outputHeight > 720 || outputWidth > 720) {
      if (outputHeight > outputWidth) {
          outputHeight = 720;
          outputWidth = Math.round(outputHeight * aspectRatio);
      } else {
          outputWidth = 720;
          outputHeight = Math.round(outputWidth / aspectRatio);
      }
  }

  }
  // console.log(img);


  // var processedImg = tf.tidy(() => {

  //   var x = tf.expand_dims(img,0);
  //   var y = tf.to_float(x);
  //   var z = tf.div(y,255);


  //   return x;


  // });
  // console.log(img)

  var processedImg = tf.tidy(() => img.expandDims(0).toFloat().div(255));

  // keep the original image to show

  var processedImg2 = tf.image.cropAndResize(processedImg, [[0.15, 0.2, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');

  var processedImg3 = processedImg2.resizeBilinear([img_height, img_width]);

  img.dispose();
  processedImg.dispose();
  return [processedImg2, processedImg3];
}

tf.enableProdMode();

async function init() {
  await tf.ready();
  var devices = await navigator.mediaDevices.enumerateDevices();
  let success = false;
  await navigator.mediaDevices.enumerateDevices().then(async function (devices) {
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === 'videoinput') {
        try {
          var webcamConfig = { deviceId: device.deviceId };
          webcam = await tfd.webcam(document.getElementById('webcam'), webcamConfig);
          success = true;
        }
        catch (e) {
        }
      }
    }
  });
  if (success == false) {
    document.getElementById("no-webcam").innerHTML = 'No webcam could be opened';
  } else {
    document.getElementById("no-webcam").innerHTML = '';
  }
  segmentationModel = await loadModel();
  console.log(tf.getBackend());
  // Warm up the model. This uploads weights to the GPU and compiles the WebGL
  // programs so the first time we collect data from the webcam it will be
  // quick.
  //var screenShot = await webcam.capture();
  //screenShot.dispose();
  predict();
}


async function initapp() {
  await tf.ready();
  var devices = await navigator.mediaDevices.enumerateDevices();

  segmentationModel = await loadModel();
 

  // Warm up the model. This uploads weights to the GPU and compiles the WebGL
  // programs so the first time we collect data from the webcam it will be
  // quick.
  //var screenShot = await webcam.capture();

}


// Initialize the application.

//tf.setBackend('cpu');
tf.ready().then(() => {initapp()});
//  tf.setBackend('webgl').then(() => init());
function tensorToMat(tensor) {
  var [height, width, channels] = tensor.shape;
  let imgData = new ImageData(width, height);
  var data = new Uint8ClampedArray(tensor.dataSync());
  imgData.data.set(data);
  return cv.matFromImageData(imgData);
}



function get_tensor_processed(inputMat) {

  outputWidth = Input_Image.width;
  outputHeight = Input_Image.height;
  
  
let aspectRatio = Input_Image.width / Input_Image.height;

if (outputHeight > 720 || outputWidth > 720) {
    if (outputHeight > outputWidth) {
        outputHeight = 720;
        outputWidth = Math.round(outputHeight * aspectRatio);
    } else {
        outputWidth = 720;
        outputHeight = Math.round(outputWidth / aspectRatio);
    }
}


  
  tensor = tf.tensor(inputMat.data, [inputMat.rows, inputMat.cols, 3])
  height = '';
  width = '';
  var image = Input_Image;
  
  
  // console.log("Inside preprocess 1");
  tensor = tf.image.cropAndResize(tensor.expandDims(0), [[0.0, 0.0, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');
  var processedImg2 = tf.tidy(() => tensor.toFloat().div(255));
  // console.log("Inside preprocess 2");
  // keep the original image to show
  //var processedImg2 = tf.image.cropAndResize(processedImg, [[0.0, 0.0, 1.0, 1.0]], [0], [outputHeight, outputWidth], 'bilinear');
  // console.log("Inside preprocess 3");
  var processedImg3 = processedImg2.resizeBilinear([img_height, img_width]);
  // console.log("Inside preprocess 4");
  return [processedImg2, processedImg3];
}
// Canvases ZOOM IN ZOOM OUT
function canvasZoomIn(display_canvas_id,input_canvas_id) {
  let canvasWidth = document.querySelector(display_canvas_id).offsetWidth;
  let canvasHeight = document.querySelector(display_canvas_id).offsetHeight;
  document.querySelector(".canvas-container").style.width =
    canvasWidth + "px";
  document.querySelector(".canvas-container").style.height =
    canvasHeight + "px";
  console.log(`width - ${canvasWidth}, height - ${canvasHeight}`);
  var displayCanvas = document.querySelector(display_canvas_id);
  var inputCanvas = document.querySelector(input_canvas_id);
  var scale = (SCALE_ZOOM /=
    SCALE_MULTIPLIER);
  if(LiveTryOn && scale <= MAX_SCALE_ZOOM ){
    displayCanvas.style.transformOrigin = 'top center';
    displayCanvas.style.transform = `scale(${-scale}, ${scale})`;
    inputCanvas.style.transformOrigin = 'top center';
    inputCanvas.style.transform = `scale(${-scale}, ${scale})`;
  } 
  else if (scale <= MAX_SCALE_ZOOM) {
    displayCanvas.style.transformOrigin = 'top center';
    displayCanvas.style.transform = `scale(${scale}, ${scale})`;
    inputCanvas.style.transformOrigin = 'top center';
    inputCanvas.style.transform = `scale(${scale}, ${scale})`;
  } else {
    var scale = (SCALE_ZOOM *=
      SCALE_MULTIPLIER);
  }
}

function canvasZoomOut(display_canvas_id,input_canvas_id) {
  var displayCanvas = document.querySelector(display_canvas_id);
  var inputCanvas = document.querySelector(input_canvas_id);
  var scale = (SCALE_ZOOM *=
   SCALE_MULTIPLIER);
   if(LiveTryOn && scale >= MIN_SCALE_ZOOM){
    displayCanvas.style.transform = `scale(${-scale}, ${scale})`;
    inputCanvas.style.transform = `scale(${-scale}, ${scale})`;
   } 
  else if(scale >= MIN_SCALE_ZOOM) {
    displayCanvas.style.transform = `scale(${scale}, ${scale})`;
    inputCanvas.style.transform = `scale(${scale}, ${scale})`;
  } else {
    var scale = (SCALE_ZOOM /=
      SCALE_MULTIPLIER);
  }
}
 async function resetZoom() {
  SCALE_ZOOM = DEFAULT_SCALE_ZOOM;
  SCALE_MULTIPLIER = DEFAULT_SCALE_MULTIPLIER;
  Input_Canvas.style.transform = '';
  Output_Canvas.style.transform = '';
  Output_Video.style.transform = '';
}

let imageContainer = document.getElementById('imageContainer');
let imgElement = document.getElementById('img2');
let inputElement = document.getElementById('fileInput');
// var preLoadedImages = imageContainer.querySelectorAll('.modal-img');
// // preLoadedImages.forEach(image => {
// //   image.addEventListener('click', () => {
// //     imgElement.src = image.src;
// //     document.getElementById('myModal').style.display='none';
// //   });
// // });
// Upload Image Function
inputElement.addEventListener('change', async (e) => {
  var file = e.target.files[0];
  if (file) {
    var fileNameParts = file.name.split('.');
    var fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    var isHEIC = fileExtension === 'heic' || fileExtension === 'heif';
    if (isHEIC) {
      // Convert HEIC Image to JPG
      var blob = await heic2any({ blob: file, toType: 'image/jpeg' });
      var jpgFile = new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
      compressAndDisplay(jpgFile);
    } else {
      compressAndDisplay(file);
    }
  }
});
// Compressing the Image and Displaying it.
async function compressAndDisplay(file) {
if (!file) {
  console.error('File is undefined or null.');
  return;
}
var fileSize = file.size;
// Checking the Image Size and Adjusting the Quality accordingly.
let quality = fileSize <= 1024 * 1024 ? 0.8 : 0.2;
try {
  var compressedFile = await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
  imgElement.src = URL.createObjectURL(compressedFile);
} catch (error) {
  console.error('Compression failed:', error);
}
}

Input_Image.style.display = 'none';
document.getElementById('webcam').style.display = 'none';

imgElement.onload = async function () {
// console.log("Here is the code 1")
  // console.log("Here is the code 1")
Tryon_Media_Container.style.display='none';
Try_On_Area.style.display = 'block';
Flipper_Icon.style.display = 'none';
//segmentationModel = await loadModel();
Output_Canvas.style.display = 'block';
Back_Button.style.display = 'block';
Scroll_Down_Target.scrollIntoView({ behavior: 'smooth',block: 'start'});
  
  /////////////////////////////////***********************
  
  
      // Extract the white areas from the original image
 //var grayscaleImage = image.mean(2);
   // Threshold the grayscale image to identify hair regions
   /*
    var threshold = 0.9; // Adjust threshold as needed
    var hairAreas = tf.cast(tf.greater(weight, threshold), 'float32');
    
     

    // Mask the grayscale image with the hair areas
    var hairGrayscale = grayscaleImage.mul(hairAreas);
     var edges = applySobelFilters(hairGrayscale);
     
     
     
    var edgeThreshold = 0.9; // Adjust as needed
  var edgeMask = edges.less(edgeThreshold).toFloat(); //
  var edgeMask2= edges.mul(edgeMask)
  


    // Threshold the hair grayscale image to identify white areas
    var percentile = 90; // Adjust percentile as needed
    var hairMainValue = calculatePercentile(hairGrayscale.dataSync(), percentile);

    // Calculate the threshold based on the hair's main value
    var whiteThreshold = hairMainValue * 0.90; 
    
    var mask=tf.cast(tf.greater(hairGrayscale, whiteThreshold), 'float32')
    
    var whiteAreas = tf.onesLike(hairGrayscale).sub(hairGrayscale.mul(edgeMask2));
    
    var resultTensor = hairGrayscale.mul(edgeMask2).div(2)
    //var resultTensor = mask.mul(whiteAreas)
    // Blend the recoloring color with the white areas of the original image
    */
    
    
  
  //////////////////////////////////*****************************
  
  
  
  
  let mat = cv.imread(imgElement);
  var inputMat = new cv.Mat();
  cv.cvtColor(mat, inputMat, cv.COLOR_RGBA2RGB);
  // console.log("Here is the code2")
  processed = get_tensor_processed(inputMat)
  
  showImage = processed[0]
  processedInputMat = processed[1]
  var maskCanvasShow = Output_Canvas;
   var originalCanvas = Input_Canvas;
  
//  console.log("Here is the code3")
  var maskPromise = tf.browser.toPixels(showImage.squeeze(), maskCanvasShow);
  var originalPromise = LiveTryOn ? Promise.resolve() : tf.browser.toPixels(showImage.squeeze(), originalCanvas);
  await Promise.all([maskPromise, originalPromise]);
  canvasZoomIn('#outputCanvas','#tryon-live-canvas-0');
  canvasZoomOut('#outputCanvas','#tryon-live-canvas-0');
  if (window.matchMedia("(max-width: 798px)").matches) { 
    Tryon_Container.style.width = "100vw";
  }
  else{
    Tryon_Container.style.width = Output_Canvas.offsetWidth + "px"; 
  }
  mat.delete();
  inputMat.delete();
};

document.addEventListener('DOMContentLoaded', function () {
  function resetContainerTryonMedia() { 
    if (window.matchMedia("(max-width: 798px)").matches) { 
      Tryon_Container.style.width = "100vw"; 
    } else { 
      Tryon_Container.style.width = "100%";
    } 
    Tryon_Container.style.height = "100%"; 
    Tryon_Container.style.float = "left"; 
  } 
  // Function to Start live camera
  document.getElementById('showWebcamBtn').addEventListener('click',async function (event) {
    event.preventDefault();
    Back_Button.style.display='block';
    Tryon_Media_Container.style.display='none';
    currentCamera = 'user';
    Try_On_Area.style.display = 'block';
    Output_Video.style.display = 'block';
    Output_Video.style.transform = 'scaleX(-1)';
    Input_Canvas.style.transform = 'scaleX(-1)';
    outputWidth = null;
    outputHeight = null;
    CanvasesContainer.style.width = '';
    CanvasesContainer.style.height = ''; 
    Flipper_Icon.style.display = 'block';
    LiveTryOn = true;
    continuePredicting = true;
    tf.setBackend('webgl').then(() => init()); 
  });
  document.getElementById('upload-img-section').addEventListener('click', function () {
    inputElement.click();
  });
  // Tryon Button one
  // document.getElementById('showTryonbtn').addEventListener('click', handleTryOnClick);
  // document.getElementById('showTryonbtntwo').addEventListener('click', handleTryOnClick);
  // Back Button
  document.getElementById('backButton').addEventListener('click', async function (event) {
    event.preventDefault();
    await resetZoom();
    await backOperations();
    resetContainerTryonMedia();
    outputWidth = null;
    outputHeight = null;
    CanvasesContainer.style.width = '';
    CanvasesContainer.style.height = ''; 
    Input_Canvas.style.width='';
    await clearCanvases();
    Input_Canvas.style.transform = 'scaleX(1)';
    Try_On_Area.style.display = 'none';
    Tryon_Media_Container.style.display='block';
    if(LiveTryOn){
    await turnOffCamera();
    await stopPredict();
    }
    LiveTryOn = false;
});

    // Zoom in Zoom Out Functionalities Below
    document.getElementById('zoom-in').addEventListener('click', (event) => handleZoom(event, true));
    document.getElementById('zoom-out').addEventListener('click', (event) => handleZoom(event, false));

//Camera Flipper 
document.getElementById('flipper').addEventListener('click', async function(event){
  event.preventDefault();
  await flipCamera();
});


// Intensity update for upload image

if (window.location.pathname.includes('/builder.html')) { 
document.getElementById('strength').addEventListener('input', function (event) {
  if(!LiveTryOn){ 
  updateImageColor();
  }
});
}

  // Select Shades
  let DOMShadeColors = document.getElementsByClassName('shade-color');
  const clrField = document.querySelector('.clr-field');
  for (i = 0; i < DOMShadeColors.length; i++) {

    DOMShadeColors[i].addEventListener("click", function () {
      var rgbValues = this.getAttribute('data-rgb').split(',').map(Number);
      if (window.location.pathname.includes('/builder.html')) { 
      clrField.style.color = `rgb(${rgbValues.join(',')})`; 
      }
      const normalizedRGBDOM = rgbValues.map(value => value / 255.0);
      hairColor = normalizedRGBDOM;
      if(!LiveTryOn){
      updateImageColor();
      }

    });
  }
});
function handleTryOnClick(event) {
  event.preventDefault();
  TopContentSection.classList.remove('margin-class');
  Try_On_Controls_Section.style.display = 'block';
  Try_On_Controls_Section.scrollIntoView({ behavior: 'smooth' });
}
function handleZoom(event, zoomIn) {
  event.preventDefault();
  var canvasId = LiveTryOn ? '#mask' : '#outputCanvas';
  var zoomFunction = zoomIn ? canvasZoomIn : canvasZoomOut;
  zoomFunction(canvasId, '#tryon-live-canvas-0');
}
async function backOperations(){
  hairColor = [null,null,null];
  let Splitter_Icon = document.getElementById('spliter');
  if (Splitter_Icon.classList.contains('active')) {
    Splitter_Icon.classList.remove('active');
    document.getElementById('baseCanvasSplitter').style.display='none';
    document.querySelector('.splitter-range-container').style.display = 'none';
    document.querySelector('.splitter-handler').style.display = 'none'; 
    document.querySelector('#baseCanvasSplitter').style.width = '0';
    document.querySelector('#splitterController').style.backgroundSize = "50% 100%";
    document.querySelector('.splitter-handler').style.left = "48%";
    document.querySelector('#splitterController').value = 50;
  }
  console.log('Execution Successfull');
}
async function clearCanvases(){
  let canvasInput = Input_Canvas;
  let canvasVideo = Output_Video;
  let canvasImage = document.getElementById('outputCanvas');
  let ctx_Input = canvasInput.getContext("2d");
  let ctx_Video = canvasVideo.getContext("2d");
  let ctx_Image = canvasImage.getContext("2d");
  ctx_Input.clearRect(0, 0, canvasInput.width, canvasInput.height);
  if(LiveTryOn){
  ctx_Video.clearRect(0, 0, canvasVideo.width, canvasVideo.height);
  Output_Video.style.display = 'none';
  }
  ctx_Image.clearRect(0, 0, canvasImage.width, canvasImage.height);
  Input_Canvas.style.display = 'none';
  Output_Canvas.style.display = 'none';
}



/**
 * Create a Gaussian kernel for blurring
 * @param {number} kernelSize - Size of the kernel
 * @param {number} sigma - Standard deviation of the Gaussian
 * @returns {tf.Tensor} - Gaussian kernel
 */
function createGaussianKernel(kernelSize, sigma) {
    var kernel1D = tf.tidy(() => {
        var x = tf.linspace(-Math.floor(kernelSize / 2), Math.floor(kernelSize / 2), kernelSize);
        var gaussKernel = tf.exp(tf.neg(x.square().div(tf.scalar(2 * sigma * sigma))))
            .div(tf.scalar(Math.sqrt(2 * Math.PI) * sigma));
        return gaussKernel.div(gaussKernel.sum());
    });

    var kernel2D = tf.outerProduct(kernel1D, kernel1D);

    // Expand dimensions for RGB channels and return
    return tf.stack([kernel2D, kernel2D, kernel2D], 2).expandDims(3);
}

/**
 * Apply Gaussian blur to an image tensor
 * @param {tf.Tensor} imageTensor - Input image tensor
 * @param {number} sigma - Standard deviation of the Gaussian
 * @returns {tf.Tensor} - Blurred image tensor
 */
function gaussianBlur(imageTensor, sigma) {
    var kernelSize = Math.ceil(sigma * 6) | 1; // Ensure kernel size is odd
    var gaussianKernel = createGaussianKernel(kernelSize, sigma);

    // Debug: Log Gaussian kernel values
    console.log('Gaussian Kernel:', gaussianKernel.arraySync());

    return tf.tidy(() => {
        var blurred = imageTensor.depthwiseConv2d(gaussianKernel, [1, 1, 1, 1], 'same');

        // Debug: Check blurred image tensor values
        blurred.min().print();
        blurred.max().print();
        return blurred;
    });
}

/**
 * Sharpen an image using Gaussian blur
 * @param {tf.Tensor} imageTensor - Input image tensor
 * @param {number} alpha - Sharpening factor
 * @param {number} sigma - Standard deviation for Gaussian blur
 * @returns {tf.Tensor} - Sharpened image tensor
 */
function sharpen(imageTensor, alpha = 1.5, sigma = 5) {
    return tf.tidy(() => {
        // Normalize image to [0, 1]
        var normalizedImage = imageTensor.toFloat().div(tf.scalar(255));

        // Apply Gaussian blur
        var blurredImage = gaussianBlur(normalizedImage, sigma);

        // Calculate sharpened image: original + alpha * (original - blurred)
        var sharpenedImage = normalizedImage.add(
            normalizedImage.sub(blurredImage).mul(tf.scalar(alpha))
        );

        // Debug: Print intermediate tensor values
        sharpenedImage.min().print();
        sharpenedImage.max().print();

        // Clip values to [0, 1] and scale back to [0, 255]
        var clippedImage = sharpenedImage.clipByValue(0, 1).mul(tf.scalar(255)).toInt();

        // Debug: Check final tensor values
        clippedImage.min().print();
        clippedImage.max().print();

        return clippedImage;
    });
}


async function updateImageColor(){
 tf.engine().startScope();
  let mat = cv.imread(imgElement);
  var inputMat = new cv.Mat();
  
  let mat2=mat.clone()

  
  
  cv.cvtColor(mat2, mat2, cv.COLOR_RGB2BGR);
  
  let height2 = outputHeight; //mat2.rows; // Number of rows, which is the height of the image/matrix
let width2 = outputWidth; //mat2.cols;  // Number of columns, which is the width of the image/matrix

console.log("Height: " + height2 + ", Width: " + width2);

  
  cv.resize(mat2, mat2, new cv.Size(width2, height2), 0, 0, cv.INTER_NEAREST);
  cv.cvtColor(mat2, mat2, cv.COLOR_BGR2RGB);
  

  
  
  var output = segmentationModel.predict(processedInputMat);
  var maskCanvasshow = document.getElementById('outputCanvas');
  var start_resize = window.performance.now();
  var outputMatt = tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height, img_width]).clipByValue(0, 1).reshape([img_height, img_width, 1]).resizeBilinear([height2, width2]).squeeze());

  
  
  

  

  
  
  
  let mat2float=cv.matFromArray(height2, width2, cv.CV_32FC3, mat2.data); 

  
  
  
    let outputBlended = Algofacerecolor(mat2float,mat2float.clone(),outputMatt); 
    
     cv.resize(outputBlended, outputBlended, new cv.Size(outputWidth, outputHeight), 0, 0, cv.INTER_NEAREST);
      //  console.log("output size is ");
  
  console.log(outputWidth)
  console.log(outputHeight)
    
    
    
    var canvasOutput = document.getElementById('outputCanvas');
  //cv.imshow(canvasOutput, mat2);
	  
	  
	cv.imshow(canvasOutput, convert32FC3To8UC3(outputBlended));
    
    
    
    // console.log("Function Called")
  
  //outputBlended=sharpen(outputBlended)
  //await tf.browser.toPixels(outputBlended, maskCanvasshow);

  mat2.delete();
  inputMat.delete();
  outputBlended.delete();
  tf.engine().endScope();
}



async function updateImageColorOld(){
  tf.engine().startScope();
  let mat = cv.imread(imgElement);
  var inputMat = new cv.Mat();
  var output = segmentationModel.predict(processedInputMat);
  var maskCanvasshow = document.getElementById('outputCanvas');
  var start_resize = window.performance.now();
  var outputMatt = tf.tidy(() => tf.tensor(output.dataSync()).reshape([img_height, img_width]).clipByValue(0, 1).reshape([img_height, img_width, 1]).resizeBilinear([outputHeight, outputWidth]).squeeze());
  avg=averageLuminanceFiltered(showImage.squeeze().dataSync(),outputMatt.dataSync())
  adjustWithLuminance=mapLuminance(avg, 0.9, 1);
  additionLuminance= mapValue(adjustWithLuminance, 0.9, 1, 1, 0.8);
  
  
  
 var mask = tf.greater(outputMatt, 0.8);
 //var mask2 = (weight > 0.3) & (weight < 0.6)
 //var mask3 = (weight > 0.6) & (weight < 0.8);
 //let blendingIntensity=1.2
  if (avg<0.2 && hairColor[0] >0.5 && hairColor[1] >0.5)
  {
  blendingIntensity=1.2
  }
  outputMatt = tf.where(mask, outputMatt, outputMatt);
  
  
  
  
  let outputBlended = blendMediapipe(showImage.squeeze(),  outputMatt,adjustWithLuminance,additionLuminance,avg); //.resizeBilinear([256,256])
  
  //outputBlended=sharpen(outputBlended)
  await tf.browser.toPixels(outputBlended, maskCanvasshow);
  mat.delete();
  inputMat.delete();
  tf.engine().endScope();
}


async function flipCamera() {
  tf.engine().endScope();
  currentCamera = currentCamera === 'user' ? 'environment' : 'user'; 
  // Stop the execution of the predict function
  stopPredict();
  await webcam.stop();
  
  // Start the camera with the new configuration
  var webcamConfig = { facingMode: currentCamera };
  webcam = await tfd.webcam(document.getElementById('webcam'), webcamConfig);
  
  // Wait for the webcam stream to be fully initialized and captured
  await new Promise(resolve => setTimeout(resolve, 500));
  if (!continuePredicting) {
    continuePredicting = true;
    predict();
  }
}
function stopPredict() {
  continuePredicting = false;
      tf.engine().endScope();
}
async function isMobileDevice() {
  var userAgent = navigator.userAgent;
  var mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
  return mobileRegex.test(userAgent);
}
async function isMobileBrowser() {
  // Check if the device is mobile
  var isMobile = await isMobileDevice();

  // Check if the browser is a mobile browser (including Safari on iOS)
  var isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Mobile/i.test(navigator.userAgent);

  return isMobile || isMobileBrowser;
}

async function turnOffCamera() {
  if (webcam) {
    webcam.stop();
    console.log('Camera turned off.');
  } else {
    console.error('Webcam is not initialized.');
  }
}

// arrShadesData.tabs.forEach(tab => {
//   var tabButton = document.createElement('button');
//   tabButton.textContent = tab.label;
//   tabButton.classList.add('tablink');
//   tabButton.setAttribute('data-tab', tab.id);
//   tabButton.onclick = () => openTabContent(tab.id); 
//   tabsContainer.appendChild(tabButton);
//   if (tab.id === "brown") {
//       tabButton.classList.add('active');
//   }
// });

// arrShadesData.color_palettes.forEach(palette => {
//   var paletteElement = document.createElement('ul');
//   paletteElement.classList.add('color-palette', 'tabcontent');
//   paletteElement.id = palette.id;
//   palette.shades.forEach(shade => {
//       var shadeElement = document.createElement('li');
//       shadeElement.classList.add('color-shade');
//       shadeElement.innerHTML = `
//           <div class="shade-color" data-rgb="${JSON.stringify(shade.rgb)}">
//               <img src="${shade.image_src}" alt="Image" />
//           </div>
//           <span class="shade-name" style="display: none;">${shade.name}</span>
//       `;
//       shadeElement.querySelector('.shade-color').addEventListener('click', function() {
//           toggleShadeNameVisibility(shade.name);
//       });
//       paletteElement.appendChild(shadeElement);
//   });
//   colorPaletteContainer.appendChild(paletteElement);
// });

// function toggleShadeNameVisibility(shadeName) {
//     selectedShadeNameDiv.textContent = shadeName;
//     selectedShadeNameDiv.style.display = 'block';
// }

// function openTabContent(tabId) {
//   // Iterate over tab contents
//   var tabContents = document.querySelectorAll('.tabcontent');
//   tabContents.forEach(tabContent => {
//       // Hide all tab contents except the one with the matching tab ID
//       if (tabContent.id === tabId) {
//           tabContent.style.display = 'block';
//       } else {
//           tabContent.style.display = 'none';
//       }
//   });

//   // Update active class for the tab button
//   var selectedTabButton = document.querySelector(`button[data-tab="${tabId}"]`);
//   if (selectedTabButton) {
//       // Remove active class from all tab buttons
//       var tabButtons = document.querySelectorAll('.tablink');
//       tabButtons.forEach(tabButton => {
//           tabButton.classList.remove('active');
//       });
//       selectedTabButton.classList.add('active');
//   }
// }

// // Initial setup: Open default tab content and set active class
// openTabContent('brown');
